<template>
  <div>
    <el-page-header
      icon="el-icon-arrow-left"
      content="创建课程信息"
      @back="goBack"
    ></el-page-header>
    <el-form
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="140px"
      class="follow-form"
    >
      <el-form-item label="跟读英文：" prop="sentence">
        <el-input class="follow-input" v-model="ruleForm.sentence"></el-input>
      </el-form-item>
      <el-form-item label="跟读中文：" prop="sentence_name">
        <el-input
          class="follow-input"
          v-model="ruleForm.sentence_name"
        ></el-input>
      </el-form-item>
      <el-form-item label="句子连读：" prop="sentence_content">
        <el-input
          class="follow-input"
          v-model="ruleForm.sentence_content"
        ></el-input>
      </el-form-item>
      <el-form-item label="示范音频：" prop="audio">
        <div class="follow-update">
          <div v-if="src" style="display: flex; margin-right: 20px">
            <audio style="height: 40px" :src="src" controls="controls"></audio>
          </div>
          <div class="update-group">
            <el-button type="primary" size="small" round>上传</el-button>
            <input
              name="audio"
              class="update-btn"
              type="file"
              accept="audio/*"
              @change="handUpdate"
            />
            <el-input v-model="ruleForm.audio" class="follow-audio"></el-input>
          </div>
          <span>仅支持mp3格式</span>
        </div>
      </el-form-item>
      <el-form-item label="所属阶段：" prop="level_id">
        <el-radio-group v-model="ruleForm.level_id" @change="handChange">
          <el-radio label="1">U1-L1</el-radio>
          <el-radio label="2">U2-L4</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="所属主题：" prop="program_id">
        <el-select
          v-model="ruleForm.program_id"
          class="follow-input"
          placeholder="全部"
        >
          <el-option
            v-for="(item, index) in programList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否可作为备选：" prop="is_alternative">
        <el-radio-group v-model="ruleForm.is_alternative">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提 交</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { themeTree } from "@/api/apiList/operate-api";
import {
  addFollowTask,
  getFollowTaskInfo,
  updateFollowTask,
  uploadVoice,
} from "@/api/apiList/content-api";
export default {
  data() {
    return {
      fileList: [],
      ruleForm: {
        id: "",
        sentence: "",
        sentence_name: "",
        sentence_content: "",
        audio: "",
        level_id: "",
        program_id: "",
        is_alternative: "",
      },
      rules: {
        sentence: [
          { required: true, message: "请输入跟读英文", trigger: "blur" },
        ],
        sentence_name: [
          { required: true, message: "请输入跟读中文", trigger: "blur" },
        ],
        level_id: [
          { required: true, message: "请选择课程阶段", trigger: "change" },
        ],
        program_id: [
          { required: true, message: "请选择课程主题", trigger: "change" },
        ],
        is_alternative: [
          { required: true, message: "请选择是否备选", trigger: "change" },
        ],
      },
      src: "",
      programList: [],
      loading: true,
      isAdd: true,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 音频上传
    handUpdate(e) {
      let obj = {
        audio: e.target.files[0],
        cateId: "3588",
      };
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      uploadVoice(formData).then((res) => {
        if (res.result === 200) {
          this.src = res.data;
          this.ruleForm.audio = res.data;
          this.$message.success({
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message.error("上传失败");
        }
      });
    },
    // 阶段选择，渲染主题列表
    handChange() {
      this.ruleForm.program_id = "";
      this.handList(this.ruleForm.level_id);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.audio) {
            let formData = new FormData();
            for (let key in this.ruleForm) {
              formData.append(key, this.ruleForm[key]);
            }
            if (this.isAdd) {
              addFollowTask(formData).then((res) => {
                if (res.result === "200") {
                  this.$message({
                    type: "success",
                    message: res.data.info,
                    duration: 1500,
                    onClose: () => {
                      this.$router.go(-1);
                    },
                  });
                }
              });
            } else {
              updateFollowTask(formData).then((res) => {
                if (res.result === "200") {
                  this.$message({
                    type: "success",
                    message: res.data.info,
                    duration: 1500,
                    onClose: () => {
                      this.$router.go(-1);
                    },
                  });
                }
              });
            }
          } else {
            this.$message.error("请上传音频文件");
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.src = "";
    },
    handList(e) {
      let obj = {
        level_id: e,
      };
      themeTree(obj).then((res) => {
        if (res.result === "200") {
          this.programList = res.data;
        }
      });
    },
  },
  mounted() {
    this.isAdd = this.$route.params.id > 0 ? false : true;
    if (this.$route.params.id > 0) {
      let obj = {
        id: this.$route.params.id,
      };
      getFollowTaskInfo(obj).then((res) => {
        if (res.result === "200") {
          this.ruleForm.id = this.$route.params.id;
          this.ruleForm.sentence = res.data.sentence;
          this.ruleForm.sentence_name = res.data.sentence_name;
          this.ruleForm.sentence_content = res.data.sentence_content;
          this.ruleForm.audio = "";
          this.src = res.data.audio;
          this.ruleForm.level_id = res.data.level_id;
          this.ruleForm.program_id = res.data.program_id;
          this.ruleForm.is_alternative = res.data.is_alternative;

          this.handList(res.data.level_id);
          this.loading = false;
        }
      });
    } else {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.follow-form {
  margin-top: 50px;

  .follow-input {
    width: 300px;
  }

  .follow-update {
    display: flex;
    align-items: center;

    .update-group {
      position: relative;

      .update-btn {
        position: absolute;
        width: 56px;
        height: 32px;
        top: 4px;
        left: 0;
        z-index: 1;
        opacity: 0;
      }

      .follow-audio {
        position: absolute;
        width: 56px;
        height: 32px;
        top: 4px;
        left: 0;
        z-index: -1;
        opacity: 0;
      }
    }

    span {
      margin-left: 20px;
      color: #ff0000;
    }
  }
}
</style>
